import React, { useEffect } from 'react';
import { getURLParams } from './lib/utils';
import './App.css';

const DEV_URL = 'http://localhost:3000';
const PROD_URL = 'https://devbox.evokedset.cloud';

function resolveURL() {
  const url = window.location.hostname === 'localhost' ? DEV_URL : PROD_URL;
  return url;
}

function App() {
  const params = getURLParams();
  useEffect(() => {
    async function setMailchimpTag() {
      fetch(`${resolveURL()}/api/marvolo`, {
        method: 'POST',
        body: JSON.stringify({ params }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then(async (res) => {
        const status = await res.json();
        console.log('output: ', status);
      });
    }
    setMailchimpTag();
  }, []);
  return (
    <main>
      {params.anid && <h3>email: {params.anid}</h3>}
      <h3>tag: {params.tag || 'TestSubject (default)'}</h3>
      <h3>new status: {params.status || 'active (default)'}</h3>
    </main>
  );
}

export default App;
